
<template>
  <!-- 订单管理 -->
  <div class="">
    <ax-table
      ref="logTable"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :dataSourceApi="api.list"
        :scroll="{ y: '55vh', x: '80vw' }"
      @action-column-click="actionColumnClick"
    >
    <template slot="status" slot-scope="{ record }">
            <div v-if="record.status === 0">未支付</div>
            <div v-else-if="record.status === 1">已完成</div>
            <div v-else-if="record.status === 2">退款中</div>
            <div v-else-if="record.status === 3">已关闭</div>
    </template>
    </ax-table>
    <detaila ref="detaila"></detaila>
  </div>
</template>
   
   <script>
const searchForm = [
  {
    label: "服务项目",
    type: "",
    model: "productName",
    options: {
      placeholder: "请输入服务项目",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "订单编号",
    type: "",
    model: "orderSn",
    options: {
      placeholder: "请输入订单编号",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "认证公司",
    type: "",
    model: "enterpriseName",
    options: {
      placeholder: "请输入认证公司",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "支付状态",
    type: "select",
    model: "status",
    options: {
      placeholder: "请选择支付状态",
      options: [
        { label: "未支付", value: 0 },
        { label: "已完成", value: 1 },
        { label: "退款中", value: 2 },
        { label: "已关闭", value: 3 },
      ],
    },
    formItem: {},
    col: { span: 6 },
  }
];
import api from "./api";
import { initGridFormData } from "@/common/tools";
import detaila from "./components/details.vue";
export default {
  components: { detaila },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "订单编号",
            dataIndex: "orderSn",
            ellipsis: true,
            key: 1,
            width: 120,
          },
          {
            title: "服务项目",
            dataIndex: "productName",
            ellipsis: false,
            key: 2,
            width: 80,
          },
          {
            title: "认证公司",
            dataIndex: "enterpriseName",
            ellipsis: true,
            key: 3,
            width: 100,
          },
          {
            title: "订单状态",
            dataIndex: "status",
            ellipsis: false,
            width: 100,
            key: 4,
          },
          {
            title: "服务费用",
            dataIndex: "payAmount",
            ellipsis: false,
            width: 80,
            key: 5,
          },
          {
            title: "客户名称",
            dataIndex: "memberName",
            ellipsis: false,
            key: 6,
          },
          {
            title: "身份证号码",
            dataIndex: "memberIdCard",
            ellipsis: false,
            key: 6,
          },
          {
            title: "下单时间",
            dataIndex: "createTime",
            ellipsis: false,
            key: 7,
          },
        ],
        true,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  type: "#E6A23C",
                  name: "detail",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },

          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),
      visible: false,
    };
  },

  methods: {
      // 操作
      async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "detail":
        this.$refs.detaila.openModal(record);
          break;
      }
    },
    // 表格刷新
    refresh() {
      this.$refs.logTable.getDataSource();
    },
    // 启用状态

  },

  created() {},

  mounted() {},
};
</script>
   <style lang='less' scoped>
</style>